<template>
    <section class="privacy-text-section">
        <div class="container">
              <h1>Privacy Policy</h1>
              <p>Responsible practices to ensure data protection and privacy This Privacy policy enunciates the privacy and data protection principles followed by Devqode and its affiliates with regards to collection, use, sharing and processing of personal information and personal data obtained in connection with the use of this website. This Privacy policy applies to processing of personal information or personal data of visitors and existing and prospective users of Devqode and its affiliates, Customers, Suppliers, Agents, Business partners and their representatives</p>
              <h2>The Data We Collect About You</h2>
              <p>We may collect, use, store and transfer different kinds of personal data about you which we have grouped together follows</p>
              <ul>
                <li>Identity Data includes username or similar identifier.</li>
                <li>Contact Data includes email address.</li>
                <li>Usage Data includes information about how you use our Site, products and services.</li>
                <li>Content Data includes any content which you upload onto the Site such as comments and other text or images that contain personal data.</li>
              </ul>
              <h2>How Is Your Personal Data Collected?</h2>
              <p>We may collect, use, store and transfer different kinds of personal data about you which we have grouped together follows:</p>
              <ul>
                <li>Direct interactions. You may give us your Identity and Contact by filling in forms or by corresponding with us by post, phone, email, online or otherwise.</li>
                <li>Third parties or publicly available sources. We may receive personal data about you from various third parties and public sources as set out below:</li>
                <li>Technical Data from the following parties: (i) analytics providers (ii) advertising networks; and (iii) search information providers.</li>
                <li>Identity, Profile and Contact Data from publicly available sources such as Companies House.</li>
                <li>Identity, Profile and Contact Data from data providers who obtain such data from publicly available sources.</li>
                <li>We collect your personal information for various purposes, for processing your order and corresponding with you.</li>
                <li>We may combine the information we collect from you with information obtained from other sources to help us improve its overall accuracy and completeness, and to help us better tailor our interactions with you.</li>
                <li>In addition, we may also collect information relating to your use of our Web sites through the use of various technologies, including cookies.</li>
                <li>We collect your personal information for various purposes, for processing your order, corresponding with you, providing you with a subscription or in connection with a job application.</li>
                <li>We may combine the information we collect from you with information obtained from other sources to help us improve its overall accuracy and completeness, and to help us better tailor our interactions with you.</li>
                <li>In addition, we may also collect information relating to your use of our Web sites through the use of various technologies, including cookies.</li>
              </ul>
              <p>Cookies are small text files that are stored on a user’s computer and allow websites to remember information about users, such as their geo-location. We use cookies, to customise content and advertising, to provide social media features and to analyse traffic to the site. We also share information about your use of our site and your email address with our trusted social media, advertising and analytics partners.
                You may also turn off cookies in your browser.</p>
              <p>If you have questions about our privacy policy, please email us at: ceo@devqode.com</p>
              <h2>Uses</h2>
              <ul>
                <li>We do not share your information with other companies, nor with the government unless legally required to do so.</li>
                <li>To fulfill your requests by us.</li>
                <li>To contact you for customer satisfation surveys, market research or in connection with certain transactions.</li>
                <li>To support products or services you have obtained from us.</li>
                <li>For marketing purposes if you have permitted such use.</li>
                <li>To personalize your experience while you are on our sites,make navigation easier, and for Web site usage statistics.</li>
              </ul>
              <h2>Data Security</h2>
              <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so. We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline</p>
              <h2>What We May Need From You</h2>
              <p>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>
              <h2>Time Limit To Respond</h2>
              <p>We try to respond to all legitimate requests within 2-3 business days. In case if there is any delay, we will notify you and keep you update.</p>
              <h2>Grievance Redressal</h2>
              <p>Any grievance or complaint, in relation to processing of information, should be sent to Devqode Corporation at ceo@devqode.com. Grievance shall be redressed as expeditiously as possible.</p>
              <h2>Changes In The Policy</h2>
              <p>This policy may be updated keeping your privacy as our priority. Any updates on the same will be updated here for your reference. Please feel free to get in touch with us for any clarification at the below details</p>
              <h2>Contact Details</h2>
              <p>Our full details are</p>
              <ul>
                <li>Email address: ceo@devqode.com</li>
              </ul>
        </div>
    </section>
</template>

<script>
export default {
}
</script>

<style lang="sass" scoped>
@import '@/pages/privacy/sections/privacyTextBlock.sass'
</style>