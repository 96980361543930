<template>
    <section class="intro text-center" id="section-one">
        <v-container fill-height>
            <v-layout justify-center align-center flex-column>
                    <div class="intro__header">
                        <h1 class="intro__title" v-html="$t(title)"></h1>
                        <p class="intro__subtitle">
                            {{ $t(subtitle) }}
                        </p>
                    </div>
            </v-layout>
        </v-container>
    </section>
</template>

<script>
export default {
    props: ['title', 'subtitle']
}
</script>

<style lang="sass">
@import '@/pages/asections/sectionHead.sass'
</style>