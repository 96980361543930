<template>
    <div class="privacy-page">
        <section-head title="sectionhead.title-privacy" subtitle="sectionhead.subtitle-privacy"/>
        <section-privacy-text-block/>
        <section-footer paragraph="sectionfooter.paragraph-default"/>
    </div>
</template>

<script>
import sectionHead from '@/pages/asections/sectionHead.vue';
import sectionFooter from '@/pages/asections/sectionFooter.vue';
import privacyTextBlock from '@/pages/privacy/sections/privacyTextBlock.vue';

export default {
    metaInfo() {
        return {
            title: 'Privacy policy - Devqode',
            keywords: this.$t('meta_keywords'),
            description: this.$t('meta_privacy-description')
        }
    },
    data:() => ({
        options: {
            description: {
                headtext: 'exchange.desc.headtext',
                maintext: 'exchange.desc.maintext',
                bottomtext: 'exchange.desc.bottomtext'
            }
        }
    }),
    components: {
        'section-head': sectionHead,
        'section-footer': sectionFooter,
        'section-privacy-text-block': privacyTextBlock
    }
}
</script>

<style lang="sass" scoped>
@import '@/pages/privacy/privacyPage.sass'
.exchange-page
    color: $color--dark
</style>